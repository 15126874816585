// @flow
import type { CategoryType, DipseaContentType } from '../flowTypes'
import { convertDeepLink, sortSlugs } from './helpers'
import { getAllCategoriesByTypeAsync, getCategoryByIdAsync, getCategoryBySlugAsync } from './Category'
import type { TrackPropsType } from './Track'
import { convertTrackToProps } from './Track'
import { getLibrary } from '../services/dipseaApi'
import type { HorizontalMediumTrackRowPropsType, HorizontalMediumBookRowPropsType } from './Discover'
import type { BookInfoPropsType } from './Book'
import { convertBookToBookInfo } from './Book'

export type CharacterInfoPropsType = { title: string, description: string | null, imageUrl: string, slug: string, signatureImageUrl: string | null }
export type CharactersListPropsType = {
  title: string,
  description: string | null,
  characters: CharacterInfoPropsType[]
}

export function convertCategoryToCharacterInfoProps (category: CategoryType): CharacterInfoPropsType {
  const slug = sortSlugs(category.slugs)[0]
  return {
    title: category.display_name,
    description: category.description || null,
    imageUrl: category.hunk_profile_image_url || '',
    signatureImageUrl: category.header_image_url || null,
    slug: `/characters/${slug}`
  }
}

export const characterCategoryId = '-L3dT3pe6GHUnkX34y7g'

export async function convertCharacterCategoryToListPropsAsync (profileImage?: true): Promise<CharactersListPropsType> {
  const [characterCategory, characters] = await Promise.all([
    getCategoryByIdAsync(characterCategoryId),
    getAllCategoriesByTypeAsync('hunks')
  ])
  return {
    title: characterCategory.display_name,
    description: characterCategory.description,
    characters: characters.map((character: CategoryType): CharacterInfoPropsType => convertCategoryToCharacterInfoProps(character))
  }
}

export async function convertCategoryToCharacterInfoByIdProps (id: string): Promise<CharacterInfoPropsType> {
  const character = await getCategoryByIdAsync(id)
  return convertCategoryToCharacterInfoProps(character)
}

export async function convertCategoryToCharacterInfoBySlugProps (slug: string): Promise<CharacterInfoPropsType> {
  const character = await getCategoryBySlugAsync(slug)
  return convertCategoryToCharacterInfoProps(character)
}

export type CharacterPageRowType = HorizontalMediumBookRowPropsType |
  HorizontalMediumTrackRowPropsType
export function convertCharacterPageRowsToProps (category: CategoryType, library: DipseaContentType): CharacterPageRowType[] {
  const pageRows: CharacterPageRowType[] = []
  const slug = sortSlugs(category.slugs)[0]
  if (category.pageRows) {
    Object.keys(category.pageRows).forEach((key: string): void => {
      const pageRow = category.pageRows[key]
      let cta = pageRow.cta || null
      if (cta) {
        let url = cta.deepLink
        if (url.includes('moreLikeHunkActor')) {
          url = `/characters/${slug}/more-like-actor/${url.split('actorId=')[1]}`
        }
        cta = {
          url: convertDeepLink(url),
          title: cta.name
        }
        // http://local.dipseastories.com:3000/trackList/?type=moreLikeHunkActor&hunkId=-NVVeAe0ieVx8jg4DaLB&actorId=-NRA8UP64mvnUrorXGAe
      }
      if (pageRow.displayType === 'horizontalMediumTrackRow') {
        pageRows.push({
          displayType: 'horizontalMediumTrackRow',
          items: pageRow.ids.map((id: string): TrackPropsType => convertTrackToProps({ id, ...library.tracks[id] }, library)).filter((track: TrackPropsType | null): boolean => !!track),
          title: pageRow.title.replace('Pillowtalks', 'Character Experiences'),
          description: pageRow.description || null,
          cta
        })
      }

      if (pageRow.displayType === 'horizontalMediumBookRow') {
        pageRows.push({
          displayType: 'horizontalMediumBookRow',
          items: pageRow.ids.map((id: string): BookInfoPropsType => convertBookToBookInfo({ id, ...library.books[id] }, library)),
          title: pageRow.title,
          description: pageRow.description || null,
          cta
        })
      }
    })
  }
  return pageRows
}

export type CharacterPropsType = {
  id: string,
  title: string,
  description: string | null,
  imageUrl: string,
  slug: string,
  pageRows: CharacterPageRowType[]
}

export function convertCategoryToCharacterProps (category: CategoryType, library: DipseaContentType): CharacterPropsType {
  const slug = sortSlugs(category.slugs)[0]
  return {
    id: category.id,
    title: category.display_name,
    description: category.description || null,
    imageUrl: category.header_image_url,
    slug: `/characters/${slug}`,
    pageRows: convertCharacterPageRowsToProps(category, library)
  }
}

export async function convertCategoryToCharacterByIdProps (id: string): Promise<CharacterPropsType> {
  const [character, library] = await Promise.all([getCategoryBySlugAsync(id), getLibrary()])
  return convertCategoryToCharacterProps(character, library)
}

export async function convertCategoryToCharacterBySlugProps (id: string): Promise<CharacterPropsType> {
  const [character, library] = await Promise.all([getCategoryBySlugAsync(id), getLibrary()])
  return convertCategoryToCharacterProps(character, library)
}
